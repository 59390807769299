// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-page-js": () => import("../src/Templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-history-page-js": () => import("../src/Templates/HistoryPage.js" /* webpackChunkName: "component---src-templates-history-page-js" */),
  "component---src-templates-history-detail-page-js": () => import("../src/Templates/HistoryDetailPage.js" /* webpackChunkName: "component---src-templates-history-detail-page-js" */),
  "component---src-templates-about-page-js": () => import("../src/Templates/AboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-project-list-page-js": () => import("../src/Templates/ProjectListPage.js" /* webpackChunkName: "component---src-templates-project-list-page-js" */),
  "component---src-templates-project-detail-page-js": () => import("../src/Templates/ProjectDetailPage.js" /* webpackChunkName: "component---src-templates-project-detail-page-js" */),
  "component---src-templates-article-list-page-js": () => import("../src/Templates/ArticleListPage.js" /* webpackChunkName: "component---src-templates-article-list-page-js" */),
  "component---src-templates-product-page-js": () => import("../src/Templates/ProductPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-industry-page-js": () => import("../src/Templates/IndustryPage.js" /* webpackChunkName: "component---src-templates-industry-page-js" */),
  "component---src-templates-branding-page-js": () => import("../src/Templates/BrandingPage.js" /* webpackChunkName: "component---src-templates-branding-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

